import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/bestCardProcessingRatesVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles.mainWrapper}>
                    <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                        <div className={styles["main-banner"]}>
                            <Form
                                classes={{
                                    formClassName: styles["form"],
                                    stepTitleClassName: styles["step-title"],
                                    stepClassName: styles["step-block"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                        checkbox: styles["checkbox-field"],
                                        all: styles["field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    fieldNoteClassName: styles["field-note"],
                                    fieldErrorMessageClassName:
                                        styles["field-error-message"],
                                }}
                                colors={{
                                    primaryColor: colors.primaryColor,
                                    progressBar: colors.progressColor,
                                }}
                                showProgress={true}
                            />
                            {/* <div className={styles.maxWidth546}>
                        <Image
                            src="/assets/images/mobile.svg"
                            className={styles["background-img"]}
                            alt="auto-banner"
                            layout="intrinsic"
                            priority
                            width={1500}
                            height={1730}
                        />
                    </div> */}
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
