import React, { ReactElement } from "react";
import MainBanner from "@components/bestcardprocessingrates/mainBanner/mainBanner";
import BestCardProcessingRatesWrapper from "@components/layouts/bestCardProcessingRatesWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const WhyUs = dynamic(
    () => import("@components/bestcardprocessingrates/whyUs"),
);
const CardProcessor = dynamic(
    () => import("@components/bestcardprocessingrates/creditCardProcessor"),
);
const PickRightService = dynamic(
    () => import("@components/bestcardprocessingrates/pickRightService"),
);

export default function BestCardProcessingRatesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestCardProcessingRatesWrapper>
            <div className="mainWrapper">
                <MainBanner />
            </div>
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <section>
                    <OffersList offers={domain?.defaultOffers} />
                </section>
                <CardProcessor />
            </div>
            <WhyUs />
            <PickRightService />
        </BestCardProcessingRatesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
